<template>
  <div>
    <el-button @click="load">查看变动明细</el-button>
    <el-dialog append-to-body title="激活卡数量变动明细" :visible.sync="dialogTableVisible">
      <el-row :gutter="40">
        <el-col :span="4">
          <el-select v-model="searchForm.type">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="收入" :value="1"></el-option>
            <el-option label="支出" :value="2"></el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <el-date-picker
              style="width: 100%"
              v-model="searchForm.time"
              type="daterange"
              range-separator="至"
              start-placeholder="记录时间范围开始日期"
              end-placeholder="记录时间范围结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="8">
          <el-button-group>
            <el-button @click="searchGo" type="primary">搜索</el-button>
            <el-button @click="searchReset">重置</el-button>
          </el-button-group>
        </el-col>
      </el-row>
      <el-table v-loading="loading" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="type" :formatter="typeFormatter" label="类型"></el-table-column>
        <el-table-column prop="num" label="变动数量"></el-table-column>
        <el-table-column prop="log" label="记录"></el-table-column>
        <el-table-column label="记录时间">
          <template v-slot="s">{{s.row.created_at | date}}</template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ActivationLogByUid",
  props:{
    uid:{
      type:Number,
      default:0,
    }
  },
  data(){
    return{
      dialogTableVisible:false,
      loading:false,
      searchForm:{
        type:0,
        time:[],
      },
      list:[],
      total:0,
      page:1,
    }
  },
  methods:{
    typeFormatter(e){
      let str = "";
      switch (parseInt(e.type)) {
        case 1:
          str = "收入";
          break;
        case 2:
          str = "支出";
          break;
        default:
          str = "未知";
          break;
      }
      return str;
    },
    searchReset(){
      this.searchForm = {
        type:0,
        time:[],
      }
      this.searchGo();
    },
    searchGo(){
      this.page = 1;
      this.list = [];
      this.load();
    },
    load(){
      this.loading = true;
      this.dialogTableVisible = true;
      this.$u.api.card.plugin.wholesaler.activationLog({
        page:this.page,
        uid:this.uid,
        type:this.searchForm.type,
        time:this.searchForm.time
      }).then(res=>{
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
  }
}
</script>

<style scoped>

</style>