<template>
  <div>
    <el-card style="min-height: 45rem">
      <div>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input v-model="searchForm.phone" placeholder="手机号"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="searchForm.nickname" placeholder="昵称"></el-input>
          </el-col>
          <el-col :span="4">
            <el-select v-model="searchForm.level">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="代理" :value="1"></el-option>
              <el-option label="老板" :value="2"></el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <div class="flex-def flex-zBetween">
              <el-button-group>
                <el-button @click="searchGo" type="primary">搜索</el-button>
                <el-button @click="searchReset">重置</el-button>
              </el-button-group>
              <el-button @click="exportExcel">导出</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-table row-key="id" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="uid" label="用户编号"></el-table-column>
        <el-table-column prop="nickname" label="用户昵称"></el-table-column>
        <el-table-column prop="phone" label="用户手机号"></el-table-column>
        <el-table-column :formatter="levelFormat" label="批发商等级"></el-table-column>
        <el-table-column prop="activation_card" label="激活卡剩余数量"></el-table-column>
        <el-table-column prop="head_id" width="110" label="所属团队长编号" :formatter="e=>e.head_id > 0 ? e.head_id : ''"></el-table-column>
        <el-table-column prop="head_nickname" width="110" label="所属团队长昵称"></el-table-column>
        <el-table-column  prop="help_fee" label="帮扶金"></el-table-column>
        <el-table-column label="操作" width="360">
          <template v-slot="s">
            <div class="flex-def">
              <el-button @click="rechargeItem(s.row.id)">充值激活卡数量</el-button>
              <ActivationLogByUid style="margin: 0 1rem" :uid="s.row.uid"></ActivationLogByUid>
              <UserDetail :uid="s.row.uid"></UserDetail>
            </div>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
    <el-dialog append-to-body title="激活卡数量充值" :visible.sync="rechargeShow">
      <el-form>
        <el-form-item label="充值批发商编号">{{rechargeForm.id}}</el-form-item>
        <el-form-item label="充值数量">
          <el-input-number v-model="rechargeForm.change_num"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="rechargeShow = false">取 消</el-button>
        <el-button type="primary" @click="rechargeSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {wholesalerLevel2tag} from "@/view/card/plugin/wholesaler/tools";
import ActivationLogByUid from "@/components/card/wholesaler/ActivationLogByUid";
import UserDetail from "@/components/card/wholesaler/UserDetail";

const RechargeFormTpl = {
  id:0,
  change_num:0,
}
export default {
  name: "manage",
  components: {
    UserDetail,
    ActivationLogByUid,
  },

  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm:{
        phone:"",
        nickname:"",
        level:0,
      },
      rechargeForm:{...RechargeFormTpl},
      rechargeShow:false,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    rechargeItem(id){
      this.rechargeForm = {...RechargeFormTpl,id};
      this.rechargeShow = true;
    },
    rechargeSubmit(){
      this.$u.api.card.plugin.wholesaler.recharge(this.rechargeForm).then(()=>{
        this.$message.success("操作成功");
        this.rechargeShow = false;
        this.load();
      })
    },
    levelFormat(s){
      return  wholesalerLevel2tag(s.level)
    },
    searchGo(){
      this.page = 1;
      this.list = [];
      this.load();
    },
    searchReset(){
      this.searchForm = {
        phone:"",
        nickname:"",
        level:0,
      }
      this.searchGo()
    },
    exportExcel(){
      this.$u.api.card.plugin.wholesaler.userSearchExport({
        page:this.page,
        ...this.searchForm
      }).then(res=>{
        window.open(res)
      })
    },
    load() {
      this.$u.api.card.plugin.wholesaler.userSearch({
        page:this.page,
        ...this.searchForm
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>